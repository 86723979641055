var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "changePW" }, [
    _c("div", { staticClass: "Login changePW-box" }, [
      _c("div", { staticClass: "mainBox" }, [
        _c(
          "h1",
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/reviewmind/logo2.png"),
                  alt: "로고"
                }
              })
            ])
          ],
          1
        ),
        _c("h2", [_vm._v(_vm._s(_vm.$t("change_password")))]),
        _c("div", { staticClass: "form login" }, [
          _c("form", [
            _c("fieldset", [
              _vm.isLogin
                ? _c("div", { staticClass: "cover bg" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password"
                        }
                      ],
                      attrs: {
                        type: "password",
                        placeholder: "현재 비밀번호 입력"
                      },
                      domProps: { value: _vm.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password = $event.target.value
                        }
                      }
                    })
                  ])
                : _vm._e(),
              _c("div", { staticClass: "cover bg" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.newPwd,
                      expression: "newPwd"
                    }
                  ],
                  attrs: {
                    type: "password",
                    placeholder: "신규 비밀번호 입력"
                  },
                  domProps: { value: _vm.newPwd },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.newPwd = $event.target.value
                    }
                  }
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.newPwdConfirm,
                      expression: "newPwdConfirm"
                    }
                  ],
                  attrs: { type: "password", placeholder: "비밀번호 확인" },
                  domProps: { value: _vm.newPwdConfirm },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.newPwdConfirm = $event.target.value
                    }
                  }
                })
              ]),
              _c(
                "button",
                {
                  staticClass: "btn full primary",
                  attrs: { type: "button" },
                  on: { click: _vm.submit }
                },
                [_vm._v(" 비밀번호 변경 ")]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }